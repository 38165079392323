import { intercomBoot, intercomLogin, intercomLogout } from '@paperstac/common/lib/services/intercomMessenger';
import { ToastContext, useToast } from '@paperstac/design/lib/organisms/ToastProvider/ToastProvider';
import { INTERCOM_NOTECLOSINGS_APP_ID } from '@paperstac/env';
import { getDomains } from '@paperstac/helpers/lib/domain';
import { IdentityFirestoreData } from '@paperstac/types/lib/Identity';
import Script from 'next/script';
import * as React from 'react';
import { trpc } from '../services/trpc';
import useCurrentIdentity from './useCurrentIdentity';

const ConfigureIntercomMessenger = () => {
  const [identity, identityLoading] = useCurrentIdentity();
  const [hasBooted, setBooted] = React.useState<boolean>(false);
  const [isIdentified, setIdentified] = React.useState<boolean>(false);
  const { mutateAsync } = trpc.retrieveIntercomUserHash.useMutation();
  const toast = useToast();

  const boot = React.useCallback(() => {
    if (hasBooted) return;
    setBooted(true);
    intercomBoot(INTERCOM_NOTECLOSINGS_APP_ID);
  }, [hasBooted, setBooted]);

  const identify = React.useCallback(
    (identity: IdentityFirestoreData) => {
      setIdentified(true);
      mutateAsync({ domain: getDomains().NOTECLOSINGS })
        .then((userHash) => {
          intercomLogin({
            createdUnixTimestamp: identity.createdDate.seconds,
            email: identity.email,
            name: identity.isVerified ? identity.displayName : identity.email,
            uid: identity.id,
            userHash,
          });
        })
        .catch((err) => {
          toast.error(`Chat Support Error: ${err.message}`);
        });
    },
    [mutateAsync, toast]
  );

  const forget = React.useCallback(() => {
    setIdentified(false);
    intercomLogout();
  }, []);

  React.useEffect(() => {
    boot();
    if (identityLoading) return;
    if (identity && !isIdentified) identify(identity);
    if (!identity && isIdentified) forget();
  }, [boot, identity, identityLoading, forget, identify, isIdentified]);

  return (
    <Script
      id="intercom-messenger-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_NOTECLOSINGS_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `,
      }}
    />
  );
};

ConfigureIntercomMessenger.displayName = 'ConfigureIntercomMessenger';

export default React.memo(ConfigureIntercomMessenger);
