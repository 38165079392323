var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Replay } from '@sentry/replay';
import { APP_ENV } from '@paperstac/env';

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://306b41160b1c4282b9af79301c439ebf@o103159.ingest.sentry.io/5987387';
Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  tracesSampleRate: 0.01, // 1% of transactions sampled for Performance Monitoring
  replaysSessionSampleRate: 0, // 0% of user sessions
  replaysOnErrorSampleRate: 1.0, // 100% of error sessions
  integrations: [
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
});
