import { FIREBASE_FUNCTIONS_URL } from '@paperstac/env';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { User } from 'firebase/auth';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import { trpc } from '../services/trpc';

const REFRESH_MINUTES = 15; // idTokens live for 60 minutes

const TrpcProvider = ({ children }: { children: React.ReactNode }) => {
  const [isClientReady, setClientReady] = React.useState(false);
  const [authUser, isLoadingAuthState] = useAuthState(auth);
  const [queryClient] = React.useState(() => new QueryClient());
  const [trpcClient, setTrpcClient] = React.useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${FIREBASE_FUNCTIONS_URL}/trpc`,
        }),
      ],
    })
  );

  const refreshTrpcClient = React.useCallback(
    (currentUser: User | null | undefined, currentUserLoading: boolean) => {
      if (currentUserLoading) return;
      if (currentUser) {
        currentUser.getIdToken(true).then((idToken) => {
          setTrpcClient(
            trpc.createClient({
              links: [
                httpBatchLink({
                  url: `${FIREBASE_FUNCTIONS_URL}/trpc`,
                  headers() {
                    return {
                      authorization: `Bearer ${idToken}`,
                    };
                  },
                }),
              ],
            })
          );
          setClientReady(true);
        });
      } else {
        setTrpcClient(
          trpc.createClient({
            links: [
              httpBatchLink({
                url: `${FIREBASE_FUNCTIONS_URL}/trpc`,
              }),
            ],
          })
        );
        setClientReady(true);
      }
    },
    [setClientReady, setTrpcClient]
  );

  React.useEffect(() => {
    // Refresh immediately upon user change
    refreshTrpcClient(authUser, isLoadingAuthState);
    // Refresh on interval to prevent expiration
    const intervalId = setInterval(() => {
      refreshTrpcClient(authUser, isLoadingAuthState);
    }, 1000 * 60 * REFRESH_MINUTES);
    return () => clearInterval(intervalId);
  }, [authUser, isLoadingAuthState, refreshTrpcClient]);

  if (!isClientReady) return null;

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};

TrpcProvider.displayName = 'TrpcProvider';

export default TrpcProvider;
